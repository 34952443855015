@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-normal {
  text-decoration: none;
}

.text-black {
  color: #000 !important;
}

.pointer-cursor {
  cursor: pointer;
}

.back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
}

.staged-fields {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 20px !important;
}

.back .back-button {
  background-color: #fff;
  height: 26px;
  width: 26px;
  margin-right: 10px;
  border: 1px solid #cec7c7;
}

.gap-5px {
  gap: 5px !important;
}

.gap-10px {
  gap: 10px !important;
}

.gap-20px {
  gap: 20px !important;
}

.p-20px {
  padding: 20px !important;
}

.p-10px {
  padding: 10px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.p-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-10px {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.my-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.pt-10px {
  padding-top: 10px !important;
}

.pt-20px {
  padding-top: 20px !important;
}

.pt-56px {
  padding-top: 56px !important;
}

.h-100vh {
  height: 100vh;
}

.pb-10px {
  padding-bottom: 10px !important;
}

.pb-16px {
  padding-bottom: 16px !important;
}

.pb-20px {
  padding-bottom: 20px !important;
}

.py-20px {
  padding: 20px 0;
}

.px-20px {
  padding: 0 20px;
}

.card-content {
  padding: 25px 30px !important;
}

.card-content-1\/2 {
  padding: 10px 20px !important;
}

.card-footer {
  padding: 0 20px 20px 20px !important;
}

.image {
  padding: 0 20px !important;
}

.image-description {
  padding: 0 20px !important;
  height: 100% !important;
}

.flex {
  display: flex !important;
}

.text-right {
  text-align: right !important;
}

.h-full {
  height: 100% !important;
}

.items-center {
  align-items: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.flex-column {
  flex-direction: column !important;
}

.loading-card {
  padding: 20px 40px;
  height: 116px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.side-label {
  font-size: 12px !important;
  margin-left: 5px !important;
}

.w-150px {
  width: 150px !important;
}

.w-300px {
  width: 300px !important;
}

.w-full {
  width: 100% !important;
}

.border-bottom {
  border-bottom: 1px solid #c6c5c5 !important;
}

.rate-item {
  display: flex !important;
  align-items: center !important;
  height: 48px !important;
  border-top: 1px solid #c6c5c5 !important;
}

.text-gray {
  color: #acacac !important;
}

.text-green {
  color: #53936b !important;
}

.text-danger {
  color: #ce583b !important;
}

.chip {
  justify-content: center;
  border-radius: 5px;
  padding: 6px 12px;
  color: white;
  font-size: 12px;
  width: 130px;
  text-align: center;
  font-weight: 600;
}

.text-transform-normal {
  text-transform: unset !important;
}

.chip.bg-gray {
  background-color: gray;
}

.chip.bg-blue {
  background-color: #05497C;
}

.chip.bg-green {
  background-color: #0c672e;
}

.chip.bg-red {
  background-color: #a90a0a;
}

.chip.bg-orange {
  background-color: #f06b1a;
}

.text-12px {
  font-size: 12px !important;
}

.text-14px {
  font-size: 14px !important;
}

.text-18px {
  font-size: 18px !important;
}

.text-20px {
  font-size: 20px !important;
}

.text-28px {
  font-size: 28px !important;
}

.text-semibold {
  font-weight: 700 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.card-header {
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 25px;
  border-bottom: 1px solid #c6c5c5;
  font-size: 16px;
  font-weight: 600;
}

.main-container {
  max-width: 900px !important;
  padding-top: 31px !important;
  padding-bottom: 31px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.filter-container {
  padding-bottom: 31px;
}

.main-dialog > .MuiDialog-container > .MuiPaper-root {
  width: 500px !important;
}

.dialog-title {
  font-size: 21px !important;
  font-weight: 600 !important;
  padding: 20px 30px !important;
}

.dialog-content {
  font-size: 14px !important;
  padding: 0 30px !important;
  max-height: '250px';
  min-height: '40px';
}

.dialog-footer {
  display: flex;
  padding: 20px 30px 30px !important;
}

.list > li {
  align-items: baseline;
}

.list > li > span {
  font-weight: bold;
  margin-right: 0.5rem;
}

.list > li > div > span {
  font-size: 14px;
}

.footer {
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  background-color: #fff;
  border-top: 1px solid #d8d8d8;
}

.footer .footer-inner {
  margin: 0px auto;
  max-width: 900px;
  padding: 15px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.menu-item {
  padding: 11px !important;
}

.menu-item-text, .filter-select {
  font-size: 14px !important;
  text-transform: lowercase !important;
}

.menu-item-text > span, .filter-select > div::first-letter {
  font-size: 14px !important;
}

.menu-item-text > span::first-letter, .filter-select > div::first-letter {
  text-transform: capitalize !important;
}

@media print
{    
  .staged-fields, .app-bar, .footer
  {
    display: none !important;
  }
}